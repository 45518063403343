import { Test_Types_Enum } from 'graphql/generated/hasura';

export const pageIds = {
  SIGN_UP: 1,
  LOGIN: 2,
  VERIFICATION: 3,
  FORGOT_PASSWORD: 4,
  RESET_PASSWORD: 5,
  SEND_VERIFICATION_EMAIL: 6,
  RESET_PASSWORD_EMAIL: 7,
  DASHBOARD: 8,
  MY_PROFILE: 9,
  MY_DERMSCORE: 10,
  NAVBAR_COMPONENT: 11,
  FOOTER_COMPONENT: 12,
  ROLE_REQUIRED: 13,
  ERRORS: 14,
  CAMERA: 15,
  NEW_DERMSCORE_SCAN: 16,
  RESIZE_PHOTO: 17,
  CONFIRM_PHOTO: 18,
  PHOTO_SUBMISSION: 19,
  DERMSCORE_RESULTS: 20,
  ANALYZE_PHOTO: 21,
  SCAN_QR: 22,
  FIREBASE_ERRORS: 23,
  BACK_OFFICE: 24,
  ADMIN_ROLE_TOGGLE: 25,
  TABLE_FILTER: 26,
  DERMSCORE_HISTORY: 27,
  LOG_IN_VERIFICATION: 28,
  MULTI_FACTOR_AUTH: 29,
  REGISTER_TEST_KIT: 30,
  TEST_KIT_SCANNER: 31,
  TEST_KIT_BARCODE_SCANNED: 32,
  RESULTS_PRIVACY_STATEMENT: 33,
  BARCODE_CAMERA: 34,
  NOTIFICATIONS: 35,
  SCHEDULE_DNA_VISIT: 36,
  TEST_KITS_AND_RESULTS: 38,
  VISIT_SUMMARY: 39,
  MY_SKIN_SUMMARY: 40,
  SCAN_COMPLETED: 41,
  DOWNLOAD_REPORT: 42,
  BARCODE_SCAN_COMPLETED: 43,
  PROVIDERS_LANGUAGES_OF_COMMUNICATION: 44,
  MY_VISIT_SUMMARY_MODAL: 45,
  MY_APPOINTMENTS: 46,
  COMPLETE_MY_VISIT_SUMMARY_MODAL: 47,
  VONAGE: 50,
  PROVIDERS_SELECTION: 51,
  CANCEL_APPOINTMENT_MODAL: 52,
  APPOINTMENT_DETAILS: 53,
  APPOINTMENT_PREWORK: 54,
  EDIT_APPOINTMENT: 55,
  ACCOUNT_SETTINGS: 56,
  MY_PATIENTS: 57,
  MEDICATION_HISTORY: 61,
  TEST_KIT_RESULTS_PROVIDER_TAB: 58,
  DERMSCORE_SCANS_PROVIDER_TAB: 59,
  PATIENT_DETAILS_INFO_PROVIDER_TAB: 60,
  PROVIDER_USER_PENDING_REVIEW: 62,
  APPOINTMENTS_HISTORY_MY_PATIENT_PROVIDER_TAB: 63,
  MY_MEDICATION_HISTORY: 64,
  SELECT_A_PROVIDER: 65,
  CONFIRM_MFA_PROTECT: 66,
};
export const legalDocumentsIds = {
  TERMS_OF_USE: 1,
  PRIVACY_POLICY: 2,
  LAB_SAMPLE_RESULTS_PRIVACY_STATEMENT: 3,
  DNA_VISIT_RECORD_CONSENT: 4,
  GENERAL_TEST_RECORD_CONSENT: 5,
};
export const componentIds = {
  DATEPICKER: 1,
  TEST_KITS_AND_RESULTS_FILTERS: 7,
  DYNAMIC_QUESTIONNAIRE: 8,
  APPOINTMENT_PREWORK: 9,
  INACTIVITY_MODAL: 10,
  SIGN_UP_PROVIDER_MODULE: 12,
  DERMSCORE_GRAPH_CARD: 16,
  HORMONE_QUESTIONNAIRE_MODULE: 13,
  APPOINTMENTS_PATIENT_FILTER: 17,
  SELECT_FROM_LIBRARY_MODAL: 18,
  MY_PATIENTS_MODALS: 19,
  TERMS_OF_USE_APPOINTMENTS: 20,
  MEDICATION_HISTORY_DETAIL_VIEW: 21,
  CANCEL_APPOINTMENT_MODAL_PROVIDER: 22,
  SHARED_MEDIA_LIBRARY: 30,
  FIND_PATIENT_RECORD: 27,
  VIRTUAL_PHARMACY_MODAL: 28,
  AVAILABLE_SPECIALTIES_AND_LANGUAGES: 29,
  UPCOMING_APPOINTMENTS_PROVIDER: 31,
  APPOINTMENT_PRE_WORK_PRE_CAPTURE_MODAL: 33,
  PROTECTED_ROUTE_MODAL: 32,
  SUMMARY_MODAL: 34,
  PATIENT_BIO: 35,
  PROVIDER_SIDEBAR_FILTERS: 36,
  USER_NOT_MATCH_PREWORK_MODAL: 37,
};
export const DesktopBreakpoint = 900;

export const messageTemplatesIds = {
  MY_PROFILE: 3,
  MY_PERSONAL_INFO: 4,
  MY_CONTACT_INFO: 5,
  HEALTH_QUESTIONNAIRE: 6,
  APPOINTMENT_STARTING_IN_5: 19,
  APPOINTMENT_ALREADY_BEGUN: 20,
};

export const genericActionsIds = {
  SUBMIT: 1,
  CONTINUE: 2,
  NEXT: 3,
  CANCEL: 4,
  CLOSE: 5,
  EDIT: 6,
  EXPAND_ALL: 7,
  COLLAPSE_ALL: 8,
  SAVE_CHANGES: 9,
  SAVE_ALL_CHANGES: 10,
  BACK: 11,
  UPDATE: 12,
  VERIFY: 13,
  REQUIRED: 14,
  DOWNLOAD: 15,
  TRYAGAIN: 16,
  BEGINTYPINGTOLOOKUP: 17,
  SAVE_FOR_LATER: 18,
  ADD_MEDICATION: 19,
  ADD_MORE_MEDICATIONS: 20,
  SUBMIT_FORM: 21,
  SAVE: 22,
  SKIP_THIS_STEP: 23,
  SEE_MORE: 24,
  VIEW_ALL: 25,
  CONFIRM: 26,
};

export const PARAM_MODALS_IDENTIFIERS = {
  NEW_PATIENTS_REQUEST: 'new-patient-request',
  MEDIA_LIBRARY_MODAL: 'media-library-modal',
  APPOINTMENT_DETAILS_MODAL_PROVIDER_ID: 'appointment-details-modal',
  CANCEL_APPOINTMENT_MODAL_PROVIDER_ID: 'cancel-appointment-provider-modal-id',
  TEST_KIT_RESULT_MODAL_ID: 'test-kit-result-modal',
  APPOINTMENT_ID: 'appointmentId',
  SHARED_MEDIA_MODAL_ID: 'shared-media-modal',
  MY_PATIENTS_DERMSCORE_SCANS_MODAL_ID: 'dermSCORE-scan-modal',
  MY_VISIT_SUMMARY_MODAL_PATIENT_MODAL_ID: 'my-visit-summary-modal-patient',
  MY_VISIT_SUMMARY_MODAL_PROVIDER: 'my-visit-summary-modal-provider',
  CANCEL_APPOINTMENT_MODAL: 'cancel-appointment-modal',
  JOIN_APPOINTMENT_MODAL: 'join-appointment-modal',
  VIEW_APPOINTMENT_DETAILS_MODAL_PATIENT: 'view-appointment-details-modal',
  VIDEO_APPOINTMENT_MODAL: 'appointment-video-consultation',
  CHAT_APPOINTMENT_MODAL: 'appointment-chat-consultation',
  IS_VIDEO_TEST: 'appointment-test',
  ACUITY_CALENDAR_MODAL: 'acuity-modal',
  PRE_CAPTURE_MODAL: 'pre-capture-modal',
  CAMERA: 'camera',
};
export type GenericActionsIds = typeof genericActionsIds;

export const questionnairesIds = {
  REGISTER_MY_DNA_SKIN_TEST_KIT: 39,
  HEALTH_QUESTIONNAIRE: 40,
  HORMONE_QUESTIONNAIRE_FEMALE: 41,
  HORMONE_QUESTIONNAIRE_MALE: 42,
  SIGN_UP_PROVIDER: 1,
};

export const unauthenticatedQuestionnairesIds = {
  SIGN_UP_PROVIDER: 1,
};

export const USPS_TRACKING_URL =
  'https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=';
export const FEDEX_TRACKING_URL = 'https://www.fedex.com/wtrk/track/?trknbr=';
export const FEDEX_TRACKING_NO_TRACKING_NUMBER =
  'https://www.fedex.com/en-us/tracking.html';

export const DOB_HOUR_VALUE = '13:03:02.241678+00';
export const DEFAULT_COUNTRY_CODE_NUMBER = '1';
export const TEST_DEFINITIONS_IDS: { [key in Test_Types_Enum]: number } = {
  SKINTELLIGENT: 1,
  DNA_SKIN: 2,
  DNA_VITAMIN: 5,
  ZRT_INFLAMMATORY: 6,
  ZRT_HORMONE: 7,
  ZRT_HEAVY_METALS: 8,
  ZRT_NEUROTRANSMITTERS: 9,
};

export const USER_FIELDS_REQUIRED_FOR_PERSONAL_DETAILS_COMPLETE = [
  'SENSITIVE_firstname',
  'SENSITIVE_lastname',
  'SENSITIVE_email',
  'SENSITIVE_dob',
  'SENSITIVE_user_measurements',
  'SENSITIVE_user_measurements.weight',
  'SENSITIVE_user_measurements.heightFt',
  'SENSITIVE_user_measurements.heightIn',
  'SENSITIVE_etnicity',
  'SENSITIVE_gender',
  'SENSITIVE_self_identity_gender',
];

export const USER_FIELDS_REQUIRED_FOR_CONTACT_INFO_COMPLETE = [
  'SENSITIVE_phone',
  'SENSITIVE_address_information',
  'SENSITIVE_address_information.addressLine1',
  'SENSITIVE_address_information.country',
  'SENSITIVE_address_information.state',
  'SENSITIVE_address_information.zipCode',
];

export const FHIR_USER_FIELDS_REQUIRED_FOR_PERSONAL_DETAILS_COMPLETE = [
  'SENSITIVE_phone',
  'SENSITIVE_address_information',
  'SENSITIVE_address_information.address1',
  'SENSITIVE_address_information.country',
  'SENSITIVE_address_information.state',
  'SENSITIVE_address_information.zipCode',
];

export const LOCAL_STORAGE_USER_FROM_QR = 'user-from-qr';
export const LOCAL_STORAGE_USER_SCANNED_TEST_KIT_ID =
  'user-scanned-test-kit-id';
export const LOCAL_STORAGE_USER_SCANNED_TRACKING_NUMBER =
  'user-scanned-tracking-number';
export const LOCAL_STORAGE_LOGIN_BLOCKED_TIME = 'login-blocked-time';

export const SKINTELLIGENT_ERRORS = {
  SKINTELLIGENT_NOT_FACE_DETECTED: 'NoFaceDetectedError',
  SKINTELLIGENT_FACE_SEGMENTATION_ERROR: 'FaceSegmentationFailedError',
};

export const STATES_TO_EXCLUDE = [
  'American Samoa',
  'District of Columbia',
  'Guam',
  'Northern Mariana Islands',
  'Puerto Rico',
  'United States Minor Outlying Islands',
  'United States Virgin Islands',
];

export const ISO_CODE_TO_EXCLUDE = 'UM';

export const LIMIT_LOGIN_BLOCKED_TIME = 5 * 60 * 1000;
export const BLOCKED_LOGIN_EXPIRY_TIME = (
  new Date().getTime() + LIMIT_LOGIN_BLOCKED_TIME
).toString();

export const INITIAL_FILTER_DATE_WITH_TIME = '2000-01-01T00:00:00';
export const END_FILTER_DATE_WITH_TIME = '2999-12-31T23:59:59';

export const IFRAME_RECAPTCHA_CONTENT = 'google.com/recaptcha/api2/bframe';

export const MAXIMUM_BACKWARDS_YEAR = 1950;

export const ALLOWED_CHARACTERS = /[^A-Za-z0-9\-.,:;()‘' ]/g;
export const ALL_ALLOWED_CHARACTERS = /^[A-Za-z0-9\-.,:;()‘' ]+$/;
export const ALLOWED_ONLY_ALPHABETICAL_CHARACTERS_REGEX = /^[a-zA-Z']+$/;
export const ALLOWED_ONLY_ALPHABETICAL_CHARACTERS_WITH_HYPEN =
  /^[A-Za-z\- ']*$/;
export const ALLOWED_ONLY_ALPHABETICAL_AND_NUMBERS_CHARACTERS_WITH_HYPEN =
  /^[A-Za-z0-9\- ']*$/;
export const ALLOWED_ONLY_NUMBERS = /^[0-9]*$/;
export const MATCHES_NON_DIGIT_CHARACTER = /\D/g;
export const ALLOWED_CHARACTERS_CITY_FIELD = /^[a-zA-Z\s.'-]+$/;
export const ALLOWED_ONLY_ALPHABETICAL_CHARACTERS_AND_SPACES = /^[a-zA-Z\s]*$/;

// Vonage
export const DEVICE_ACCESS_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};
export const MAX_VIDEO_PARTICIPANTS = 25;
export const CURRENT_LOG_LEVEL = 0.4;
export const MOVING_AVERAGE = 0.8;
export const AUDIO_LEVEL = 0.2;

export const USER_LAST_ACTIVITY = 'USER_LAST_ACTIVITY';

export const MEDICATIONS_SECTION = 'Medications';
export const MEDICATIONS_QUESTION_ID = 99;

export const NON_ALPHABETIC_REGEX = /[^A-Za-z]/g;
export const NON_ALPHABETIC_REGEX_PRESERVES_SPACES_AND_CHARACTERS =
  /[^A-Za-z\s/()-]/g;
export const ZIP_CODE_REGEX = /^\d+$/;
export const ZIP_CODE_LENGTH_REGEX = /^\d{5}-\d{4}$/;

export const FULL_PHONE_NUMBER_SEPARATED_BY_GROUPS_REGEX =
  /\+(\d+)\s(\d+)(?:\s(\d+))?/;

export const MIN_ZIP_CODE_LENGTH = 5;

export const MAX_ZIP_CODE_LENGTH = 9;

export const DEFAULT_PRIVATE_LD_ATTRIBUTES = [
  'email',
  'name',
  'lastname',
  'phone',
  'country',
  'firstname',
];
export const KEY_CODE = {
  ENTER: 13,
};

export const INITIAL_RESEND_VERIFICATION_COUNTER = 60;
export const GOOGLE_EHR_AVAILABLE_SYSTEMS_IDENTIFIERS = {
  codex: 'CodexLabsCorp',
  skintelligent: 'Skintelligent',
  dnaVisit: 'DnaVisit',
  sapphiros: 'Sapphiros',
  zrt: 'Zrt',
  nationalProviderIdentifier: 'urn:national-provider-identifier',
};

export const LIST_BATCH_SIZE = 10;

export const SYSTOLIC_AND_DIASTOLIC_QUESTION_ID = 73;

export const DERMSCORE_GRAPH_POINT_COLOR = '#FFFFFF';
export const DERMSCORE_GRAPH_POINT_BORDER_WIDTH = 3;
export const DERMSCORE_GRAPH_POINT_SIZE = 8;
export const DERMSCORE_GRAPH_LINE_WIDTH = 2;

export const REGEX_ONLY_NUMBERS_AND_COLONS = /^[\d:]*$/;

// TODO: Add specialties and languages to the DB

export const availableSpecialties = [
  'dentist',
  'doctor',
  'nurse',
  'dermatologist',
  'cardiologist',
  'ophthalmologist',
  'orthopedicSurgeon',
  'other',
];

export const availableLanguages = [
  'english',
  'spanish',
  'french',
  'german',
  'italian',
  'japanese',
  'korean',
  'mandarin',
  'portuguese',
  'russian',
  'other',
];

export const allAvailableFilters: Record<string, string[]> = {
  specialty: availableSpecialties,
  languages: availableLanguages,
};

export const ACUITY_CALENDAR_BASE_URL =
  'https://app.acuityscheduling.com/schedule.php';

export const CURRENT_LEGAL_DOCUMENTS_URL = `${process.env.REACT_APP_WEB_BASE_URL}/legal-documents/2`;

export const VIRTUAL_PHARMACIES = {
  dosespot: 'dosespot',
  fullscript: 'fullscript',
};

export type ImageFormat =
  | 'image/jpeg'
  | 'image/png'
  | 'image/bmp'
  | 'image/tiff'
  | 'image/gif';

export const AllowedFormats: ImageFormat[] = [
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/tiff',
  'image/gif',
];

export enum EXTERNAL_NOTIFICATIONS {
  DOSESPOT = 'dosespot',
}

export const MODAL_CONTENT_TO_DOWNLOAD_ID = 'modal-content-to-download';

export const capitalizedLabels = ['DermSCORE', 'Codex'];
