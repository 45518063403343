import {
  GetUserNotificationByUserIdSubscription,
  GetUserNotificationByUserIdSubscriptionVariables,
  useGetUserNotificationByUserIdSubscription,
  useGetUserNotificationCountsByUserIdSubscription,
} from 'graphql/generated/hasura';
import { useMemo, useState } from 'react';
import { getNotificationsConfig } from '../utilities/config';

export type Notification =
  GetUserNotificationByUserIdSubscription['user_notifications'][number];

export type NotificationResult = {
  notifications: Notification[];
  total: number;
};

export interface useNotificationsResult {
  loading: boolean;
  notifications: NotificationResult | undefined;
}

export function useNotifications(
  variables: GetUserNotificationByUserIdSubscriptionVariables = {},
): useNotificationsResult {
  const [notifications, setNotifications] = useState<Notification[]>();
  const [total, setTotal] = useState<number>();
  //~ Load 'sinceFrom' date configuration for notifications lookback period ~//
  const { lookbackDays } = getNotificationsConfig();
  const sinceFrom =
    variables.sinceFrom ??
    new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate() - lookbackDays,
      ),
    ).toISOString();

  const { loading: notificationsDataLoading } =
    useGetUserNotificationByUserIdSubscription({
      variables: {
        ...variables,
        sinceFrom: sinceFrom,
      },
      fetchPolicy: 'network-only',
      onData: ({ data }) => {
        const notifications = data.data?.user_notifications ?? [];
        setNotifications(notifications);
      },
    });

  const { loading: notificationsCountLoading } =
    useGetUserNotificationCountsByUserIdSubscription({
      variables: {
        includePriorities: variables?.includePriorities ?? undefined,
        includeStatuses: variables?.includeStatuses ?? undefined,
        sinceFrom: sinceFrom,
      },
      fetchPolicy: 'network-only',
      onData: ({ data }) => {
        const total =
          data.data?.user_notifications_aggregate?.aggregate?.count ?? 0;
        setTotal(total);
      },
    });
  const result = useMemo<useNotificationsResult>(
    () => ({
      loading: notificationsDataLoading || notificationsCountLoading,
      notifications: notifications
        ? {
            notifications: notifications ?? [],
            total: total ?? 0,
          }
        : undefined,
    }),
    [notifications, notificationsCountLoading, notificationsDataLoading, total],
  );
  console.log({ notifications, result });

  return result;
}
