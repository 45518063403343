import {
  FormEvent,
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import {
  useFirebaseSmsMfa,
  useSigninWithCustomToken,
} from '../../../firebase/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'auth/context/AuthContext';
import { FirebaseLocale } from 'firebase/interfaces';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left-current.svg';
import { Roles } from '../../../firebase/interfaces';
import { onlyDigitsPattern } from 'utilities/variables';
import { AUTH_LOGIN } from 'utilities/routes';
import ButtonComponent from 'components/button/buttonComponent';
import InputComponent from 'components/inputComponent';
import Alert from 'components/alertComponent';
import parsePhoneNumberFromString from 'libphonenumber-js';
import Modal from 'components/modal/modalComponent';
import { usePatchFhirPatientPersonalDataMutation } from 'graphql/generated/remote-schema-hasura';

import {
  useGetCustomAuthTokenFromCustomAuthCodeLazyQuery,
  useUpdateCustomAuthCodeAsUsedMutation,
} from 'graphql/generated/hasura';
import { CustomAuthCodeAlertData } from './interfaces';

export const MultiFactorAuthVerify = () => {
  const [code, setCode] = useState<string>('');
  const [isSubmittingCode, setIsSubmittingCode] = useState(false);
  const firstRendered = useRef(false);
  const recaptchaContainerWrapper = useRef<HTMLDivElement>(null);
  const { user, login } = useContext(AuthContext);
  const [timeLeft, setTimeLeft] = useState(60);
  const [temporalyDisableResendActions, setTemporalyDisableResendActions] =
    useState(false);
  const [customAuthCodeAlertData, setCustomAuthCodeAlertData] =
    useState<CustomAuthCodeAlertData>({
      alertType: 'positive',
      show: false,
      text: '',
    });

  const location = useLocation();
  const setupMode = location.state?.setupMode || false;
  const deleteAccount = location.state?.deleteAccount || false;
  const phoneNumber = location.state?.phoneNumber || '';
  const country = location.state?.country;
  const locationEmail = location.state?.email;

  const [patchFhirPatientPersonalData] =
    usePatchFhirPatientPersonalDataMutation({});

  const navigate = useNavigate();

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.VERIFICATION,
  });

  const { data: firebaseData, loading: firebaseLocaleLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });

  const [, signinWithCustomToken] = useSigninWithCustomToken();

  const [getCustomFirebaseTokenFromCustomAuthCode] =
    useGetCustomAuthTokenFromCustomAuthCodeLazyQuery({
      nextFetchPolicy: 'network-only',
    });

  const [updateCustomAuthCodeAsUsed] = useUpdateCustomAuthCodeAsUsedMutation(
    {},
  );

  const firebaseLocale: FirebaseLocale = firebaseData;

  const {
    isVerified,
    isCaptchaResolved,
    handleSendVerificationCode,
    handleSendVerificationCodeResolver,
    handleVerifyCodeResolver,
    handleVerifyCode,
    errorMsg,
  } = useFirebaseSmsMfa(firebaseLocale);

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleLogin = () => navigate(AUTH_LOGIN);

  const handleSendCode = useCallback(
    async (recaptchaContainerWrapper: HTMLElement) => {
      if (recaptchaContainerWrapper) {
        if (setupMode) {
          if (user?.phoneNumber && user?.country) {
            const callingCode = user.country.replace(onlyDigitsPattern, '');
            const formattedPhoneNumber = parsePhoneNumberFromString(
              user.phoneNumber,
              { defaultCallingCode: callingCode },
            );

            if (formattedPhoneNumber) {
              await handleSendVerificationCode(
                formattedPhoneNumber.number,
                'recaptcha-container',
              );
            }
          }
        } else {
          await handleSendVerificationCodeResolver('recaptcha-container');
        }
      }
    },
    [
      handleSendVerificationCodeResolver,
      handleSendVerificationCode,
      user,
      setupMode,
    ],
  );

  const handleResendCode = () => {
    if (recaptchaContainerWrapper.current) {
      setTemporalyDisableResendActions(true);
      setTimeLeft(60);
      handleSendCode(recaptchaContainerWrapper.current);
    }
  };
  const updatePatientPersonalData = useCallback(async () => {
    if (
      isVerified &&
      (user?.signInProvider === 'google.com' ||
        user?.signInProvider === 'apple.com')
    ) {
      try {
        const response = await patchFhirPatientPersonalData({
          variables: {
            patientPersonalInfo: {
              codexID: user?.uuid || '',
              SENSITIVE_country: country || null,
              SENSITIVE_phone: phoneNumber || null,
            },
          },
        });
        if (!response.data) {
          console.log('Error while patching phone data on FHIR');
        }
      } catch (error) {
        console.log('Error while patching phone data on FHIR', error);
      }
    }
  }, [
    country,
    isVerified,
    patchFhirPatientPersonalData,
    phoneNumber,
    user?.signInProvider,
    user?.uuid,
  ]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmittingCode(true);
    if (setupMode) {
      await handleVerifyCode(code);
      setIsSubmittingCode(false);
    } else {
      if (locationEmail) {
        const { data } = await getCustomFirebaseTokenFromCustomAuthCode({
          variables: {
            customAuthCode: code,
            email: locationEmail || '',
          },
          fetchPolicy: 'network-only',
        });
        if (data?.GetCustomAuthTokenFromCustomAuthCode.token) {
          await signinWithCustomToken(
            data.GetCustomAuthTokenFromCustomAuthCode.token,
          );
          await updateCustomAuthCodeAsUsed({
            variables: {
              code: code,
              email: locationEmail || '',
            },
          });
          login();
          setIsSubmittingCode(false);
        }
      }
      await handleVerifyCodeResolver(code, deleteAccount);
      setIsSubmittingCode(false);
    }
  };

  useEffect(() => {
    if (
      recaptchaContainerWrapper.current &&
      !firstRendered.current &&
      ((!loading && locale) || (!firebaseLocaleLoading && firebaseLocale))
    ) {
      firstRendered.current = true;
      handleSendCode(recaptchaContainerWrapper.current);
    }
  }, [handleSendCode, loading, locale, firebaseLocaleLoading, firebaseLocale]);

  useEffect(() => {
    if (isVerified) {
      login();
    }
  }, [isVerified, login]);

  useEffect(() => {
    if (user?.role === Roles.PATIENT) {
      updatePatientPersonalData();
    }
  }, [updatePatientPersonalData, user?.role]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (temporalyDisableResendActions) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(+interval);
            setTemporalyDisableResendActions(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(+interval);
  }, [temporalyDisableResendActions]);

  const getRemainingTimeToRequestCode = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      remainingSeconds < 10 ? '0' : ''
    }${remainingSeconds}`;
  };

  if ((loading && !locale) || (firebaseLocaleLoading && !firebaseLocale))
    return null;

  const unexpectedError =
    !!errorMsg &&
    errorMsg !== firebaseLocale?.requiresRecentLogin &&
    errorMsg !== firebaseLocale.tooManyRequests &&
    errorMsg !== firebaseLocale?.invalidVerificationCode &&
    errorMsg !== firebaseLocale?.invalidPhoneNumber &&
    errorMsg !== firebaseLocale?.mfaCodeNotProvided &&
    errorMsg !== firebaseLocale?.mfaInfoNotFound &&
    errorMsg !== firebaseLocale?.verificationIDFail &&
    errorMsg !== firebaseLocale?.captchaCheckFailed;

  return (
    <>
      {unexpectedError && (
        <Modal
          buttons={[
            {
              label: locale.ok,
              onClick: () => navigate(AUTH_LOGIN),
              desktopFullWidth: true,
            },
          ]}
          isOpen={unexpectedError}
          title={locale.unexpectedError}
          type="warning"
        >
          {locale.pleaseTryAgain}
        </Modal>
      )}

      {errorMsg && (
        <div className="mb-[15px]">
          <Alert type="warning" text={`${locale.error} ${errorMsg}`} />
        </div>
      )}
      {customAuthCodeAlertData.show && (
        <div className="mb-[15px]">
          <Alert
            type={customAuthCodeAlertData.alertType}
            text={customAuthCodeAlertData.text}
            isNotification
            onClose={() =>
              setCustomAuthCodeAlertData({
                text: '',
                show: false,
                alertType: 'positive',
              })
            }
          />
        </div>
      )}

      <div
        data-testid="login-page"
        className="flex flex-wrap place-self-end bg-transparent overflow-hidden shadow desktop:mb-0 desktop:p-0 desktop:w-full desktop:max-w-screen-xl desktop:mx-auto"
      >
        <ButtonComponent
          type="underline"
          className="text-white text-sm normal-case"
          containerClassName="mb-6"
          Icon={ChevronLeftIcon}
          iconPosition="left"
          iconWidth="w-2.5"
          fullWidthClassName=""
          fill="fill-white"
          iconHeight="h-[12px]"
          onClick={handleLogin}
        >
          {locale.backButton}
        </ButtonComponent>
        <div className="flex flex-col gap-2.5 w-full px-5 py-[30px] desktop:px-[60px] desktop:py-[40px] bg-white bg-border rounded-lg">
          <h1 className="text-h2 text-center font-exo font-medium desktop:text-h1 text-charcoal-gray">
            {locale.verificationLoginTitle}
          </h1>
          <p className="font-medium text-center mx-16 text-med-gray">
            {deleteAccount
              ? locale.instructionTextCloseAccount
              : locale.instructionText}
          </p>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col mt-4 gap-5 desktop:w-[390px] mx-auto"
          >
            <div className="flex flex-col gap-2.5">
              <label
                className="text-base font-semibold text-dark-gray"
                htmlFor="Verification code"
              >
                {locale.verificationCodeLabel}
              </label>

              <InputComponent
                testID="verification-code-input"
                type="text"
                name="Verification Code"
                onChange={handleOnInputChange}
                value={code}
              />
            </div>
            <div className="flex justify-center w-full desktop:w-auto">
              <p className="text-center text-base font-medium text-med-gray mr-2">
                {locale?.resendCodeLink}
              </p>
              <ButtonComponent
                testID="resend-button"
                onClick={handleResendCode}
                type="underline"
                disabled={temporalyDisableResendActions}
                noUpperCase
                className="text-clc-blue text-base font-bold w-auto"
              >
                {locale.sendAgain}
              </ButtonComponent>
            </div>
            <div className="flex justify-center">
              <ButtonComponent
                testID="login-button"
                type="submit"
                paddingX="px-10"
                paddingY="py-[9.5px] desktop:py-[17px]"
                className="capitalize text-base font-exo"
                disabled={!isCaptchaResolved || isSubmittingCode || !code}
              >
                {deleteAccount ? locale.closeAccountButton : locale.loginButton}
              </ButtonComponent>
            </div>
          </form>
          {temporalyDisableResendActions && (
            <p className="font-base text-dark-gray self-center font-semibold">{`${getRemainingTimeToRequestCode(
              timeLeft,
            )} ${locale.remainingTimeForRequestingNewCode}`}</p>
          )}
          <div className="flex justify-center" ref={recaptchaContainerWrapper}>
            <div id="recaptcha-container" />
          </div>
        </div>
      </div>
    </>
  );
};
