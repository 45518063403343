import {
  useGoogleAuthSignInPopUp,
  useAppleAuthSignInPopUp,
  useGoogleSigninWithCredentials,
  useSendVerificationEmail,
} from '../../../firebase/hooks';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Enum_Legaldocument_Legaldocumenttype } from 'graphql/generated/strapi';
import { useGetLegalDocuments } from 'hooks/legalDocuments/useGetLegalDocuments';
import { ReactComponent as EmailIcon } from 'assets/icons/emailIcon.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/googleIcon.svg';
import { ReactComponent as AppleIcon } from 'assets/icons/appleIcon.svg';
import { LOCAL_STORAGE_LOGIN_BLOCKED_TIME, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { AUTH_SIGN_UP } from 'utilities/routes';
import ButtonComponent from 'components/button/buttonComponent';
import InputComponent from 'components/inputComponent';
import Alert from 'components/alertComponent';
import LegalTerms from 'components/LegalTerms';

export const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mfaInfoNotFound = location.state?.mfaInfoNotFound || false;
  const accountDeletion = queryParams.get('accountDeletion') || '';
  const resetPassword = queryParams.get('resetPassword') || '';
  const verifiedEmail = queryParams.get('verifiedEmail') || '';
  const mfaRemoved = queryParams.get('mfaRemoved') || '';
  const mfaLinkExpired = queryParams.get('mfaLinkExpired') || '';
  const mfaRequiresRecentLogin =
    queryParams.get('mfaRequiresRecentLogin') || '';
  const mfaPhoneNumberChanged = queryParams.get('mfaPhoneNumberChanged') || '';
  let mfaTooManyRequests = queryParams.get('mfaTooManyRequests') || '';
  const resendVerificationEmail =
    queryParams.get('resendVerificationEmail') || '';
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.LOGIN,
  });
  const { data: firebaseLocale, loading: firebaseLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });

  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });
  const [isResendEmail, setIsResendEmail] = useState<boolean>(false);

  const { legalDocuments } = useGetLegalDocuments({
    locale: 'en',
    legalDocumentTypes: [
      Enum_Legaldocument_Legaldocumenttype.TermsOfService,
      Enum_Legaldocument_Legaldocumenttype.PrivacyPolicy,
    ],
  });

  const [, errorMsg, handleSignInWithCredentials] =
    useGoogleSigninWithCredentials();
  const [, , handleGoogleSignInWithPopUp] = useGoogleAuthSignInPopUp();
  const [, , handleAppleSignInWithPopUp] = useAppleAuthSignInPopUp();
  const [verificationErrorMsg, handleSendVerificationEmail] =
    useSendVerificationEmail();

  const handleGoogleSignIn = () =>
    handleGoogleSignInWithPopUp(legalDocuments || []);
  const handleAppleSignIn = () =>
    handleAppleSignInWithPopUp(legalDocuments || []);
  const handleNavigateToSignup = () => {
    navigate(AUTH_SIGN_UP);
  };

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const blockedTime = localStorage.getItem(LOCAL_STORAGE_LOGIN_BLOCKED_TIME);
    const actualTime = new Date().getTime();
    if (blockedTime && actualTime < Number(blockedTime)) {
      mfaTooManyRequests = 'true';
    }
    localStorage.removeItem(LOCAL_STORAGE_LOGIN_BLOCKED_TIME);
    handleSignInWithCredentials(userData.email, userData.password);
  };
  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const handleResendVerificationEmail = async () => {
    setIsResendEmail(true);
    await handleSendVerificationEmail();
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      navigate(location.pathname, {
        replace: true,
        state: { ...location.state, mfaInfoNotFound: false },
      });
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location, navigate]);

  if ((loading && !locale) || (firebaseLoading && !firebaseLocale)) return null;

  return (
    <div
      data-testid="login-page"
      className="flex flex-wrap place-self-end bg-transparent overflow-hidden shadow desktop:mb-0 desktop:p-0 desktop:w-full desktop:max-w-screen-xl desktop:mx-auto"
    >
      {mfaInfoNotFound && (
        <div
          className="flex flex-row w-full mb-2.5"
          data-testid="mfa-not-found-alert-container"
        >
          <Alert type="warning" text={firebaseLocale?.mfaInfoNotFound} />
        </div>
      )}

      {resetPassword && (
        <div
          className="flex flex-row w-full mb-2.5"
          data-testid="reset-alert-container"
        >
          <Alert type="positive" text={locale?.resetPasswordAlert} />
        </div>
      )}

      {verifiedEmail && (
        <div
          className="flex flex-row w-full mb-2.5"
          data-testid="verified-alert-container"
        >
          <Alert type="positive" text={locale?.verifiedEmailAlert} />
        </div>
      )}

      {mfaRemoved && (
        <div
          className="flex flex-row w-full mb-2.5 mt-4"
          data-testid="mfa-removed-alert-container"
        >
          <Alert type="positive" text={locale?.mfaRemoved} />
        </div>
      )}

      {(mfaRequiresRecentLogin || mfaTooManyRequests || mfaLinkExpired) && (
        <div className="flex flex-row w-full mb-2.5 mt-4">
          <Alert
            type="warning"
            text={
              mfaRequiresRecentLogin
                ? locale?.mfaRequiresRecentLogin
                : mfaTooManyRequests
                ? locale?.mfaTooManyRequests
                : locale?.mfaLinkExpired
            }
          />
        </div>
      )}

      {mfaPhoneNumberChanged && (
        <div className="flex flex-row w-full mb-2.5 mt-4">
          <Alert type="positive" text={locale?.mfaPhoneNumberChanged} />
        </div>
      )}

      {verificationErrorMsg && (
        <div className="flex flex-row w-full mb-2.5">
          <Alert type="warning" text={firebaseLocale?.verificationEmailError} />
        </div>
      )}

      {resendVerificationEmail && (
        <div
          className="flex flex-row w-full mb-2.5"
          data-testid="resend-alert-container"
        >
          <Alert
            type="positive"
            customContent={
              <div className="flex flex-col desktop:flex-row w-full gap-[15px]">
                <p className="text-base font-semibold text-center desktop:text-start text-alert-positive">
                  {isResendEmail
                    ? locale?.resentVerificationEmailAlert
                    : locale?.resendVerificationEmailAlert}
                </p>
                <p
                  className="text-clc-blue text-base font-bold underline text-center whitespace-nowrap cursor-pointer"
                  onClick={handleResendVerificationEmail}
                >
                  {locale?.resendVerificationEmail}
                </p>
              </div>
            }
          />
        </div>
      )}
      {accountDeletion && (
        <div
          className="flex flex-row w-full mb-2.5"
          data-testid="verified-alert-container"
        >
          <Alert type="positive" text={locale?.accountDeletionInfo} />
        </div>
      )}

      {errorMsg && (
        <div className="flex flex-row w-full mb-2.5">
          <Alert type="warning" text={errorMsg} />
        </div>
      )}

      <div className="flex flex-col gap-5 w-full desktop:basis-[60%] px-5 py-[30px] desktop:px-[80px] desktop:pt-[60px] desktop:pb-9 bg-white bg-border rounded-t-lg desktop:rounded-l-lg desktop:rounded-r-none">
        <h1 className="font-exo font-medium text-h2 text-center desktop:text-h1 text-charcoal-gray">
          {locale?.welcome}
        </h1>
        <form onSubmit={handleLogin} className="flex flex-col gap-5">
          <div className="flex flex-col gap-1">
            <label
              className="font-base font-semibold text-charcoal-gray"
              htmlFor="username"
            >
              {locale?.emailOrUsernameLabel}
            </label>
            <InputComponent
              testID="email-input"
              type="email"
              name="email"
              onChange={handleOnInputChange}
            />
          </div>
          <div className="flex flex-col gap-2.5">
            <label
              className="font-base font-semibold text-charcoal-gray"
              htmlFor="username"
            >
              {locale?.passwordLabel}
            </label>
            <InputComponent
              testID="password-input"
              type="password"
              name="password"
              onChange={handleOnInputChange}
            />
          </div>
          <div className="flex justify-center mt-2.5">
            <ButtonComponent
              testID="login-button"
              type="submit"
              paddingX="px-10"
              paddingY="py-[9.5px] desktop:py-[17px]"
              className="capitalize font-bold text-base font-exo"
            >
              {locale?.logInButton}
            </ButtonComponent>
          </div>
          <Link
            className="text-clc-blue text-sm capitalize font-bold font-exo underline self-center"
            to={'/auth/forgot-password'}
          >
            {locale?.forgotPassword}
          </Link>
        </form>
      </div>
      <div className="flex flex-col gap-5 w-full desktop:basis-[40%] px-5 py-10 desktop:px-[60px] desktop:py-[60px] bg-clc-blue/50 bg-border backdrop-blur text-center desktop:rounded-r-lg desktop:rounded-l-none ">
        <div className="flex flex-col gap-2.5">
          <h2 className="font-exo text-h3 text-white font-semibold">
            {locale?.newHere}
          </h2>
          <h5 className="text-base text-white">{locale?.signUpMessage}</h5>
        </div>
        <div className="flex flex-col justify-center desktop:items-center gap-[7px]">
          <ButtonComponent
            testID="email-signup"
            onClick={handleNavigateToSignup}
            type="social"
            iconPosition="left"
            Icon={EmailIcon}
            className="desktop:w-full text-sm px-0"
            containerClassName="!justify-start text-charcoal-gray text-exo w-[190px] mx-auto"
            stroke="transparent"
            iconWidth="w-[28px]"
            iconHeight="h-[28px]"
            paddingY="py-2"
          >
            {locale?.signUpWithEmail}
          </ButtonComponent>

          <ButtonComponent
            testID="google-signup"
            onClick={handleGoogleSignIn}
            type="social"
            iconPosition="left"
            Icon={GoogleIcon}
            className="desktop:w-full text-sm px-0"
            containerClassName="!justify-start w-[190px] text-charcoal-gray text-exo mx-auto"
            stroke="transparent"
            iconWidth="w-[28px]"
            iconHeight="h-[28px]"
            paddingY="py-2"
          >
            {locale?.signUpWithGoogle}
          </ButtonComponent>
          <ButtonComponent
            testID="apple-signup"
            onClick={handleAppleSignIn}
            type="social"
            iconPosition="left"
            Icon={AppleIcon}
            className="desktop:w-full text-sm px-0"
            containerClassName="!justify-start w-[190px] text-charcoal-gray text-exo mx-auto"
            stroke="transparent"
            iconWidth="w-[28px]"
            iconHeight="h-[28px]"
            paddingY="py-2"
          >
            {locale?.signUpWithApple}
          </ButtonComponent>

          <hr className="flex flex-row w-full items-center h-px bg-neutral-white my-[30px]" />

          <p className="text-left text-white text-sm">
            <LegalTerms
              linkClasses="font-semibold"
              legalDocuments={legalDocuments || []}
              openingLabel={locale?.termsConditionsLabel}
              separatorLabel={locale?.termsConditionsLabel2}
              closingLabel={locale?.termsConditionsLabel3}
            />
          </p>
        </div>
      </div>
    </div>
  );
};
