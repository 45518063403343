import React from 'react';
import { ReactComponent as SMS } from 'assets/icons/smsMfa.svg';
import { ReactComponent as Arrow } from 'assets/icons/blue-arrow-right.svg';
import { useGetPage } from '../../../hooks/useGetPage';
import { pageIds } from '../../../utilities/constants';
import ToggleComponent from '../../../components/ToggleComponent';
import ButtonComponent from '../../../components/button/buttonComponent';
import { AUTH_LOGIN } from '../../../utilities/routes';
import { useNavigate } from 'react-router-dom';

const MultiFactorAuthConfirmProtect: React.FC = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.CONFIRM_MFA_PROTECT,
  });

  const navigate = useNavigate();

  const handleConfirmClick = () => navigate(AUTH_LOGIN);

  if (loading && !locale) return null;

  return (
    <div className="bg-transparent shadow desktop:rounded-lg desktop:mb-0 desktop:p-0 desktop:w-full desktop:max-w-screen-xl desktop:mx-auto">
      <div className="w-full bg-white bg-border rounded-t-lg desktop:basis-3/5">
        <div className="flex flex-col gap-2.5 border-b desktop:flex-row desktop:items-center desktop:justify-between px-5 py-5 desktop:px-[60px] desktop:pt-[30px]">
          <h3 className="text-h4 desktop:text-h3 text-dark-gray font-semibold">
            {locale.title}
          </h3>
        </div>
        <div className="flex flex-col gap-2.5 px-5 py-[30px] desktop:px-[60px] desktop:pt-[30px]">
          <p className="text-med-gray font-semibold">{locale.subtitle}</p>
        </div>
        <div className="flex relative flex-col p-[15px] mx-[30px] mb-[30px] rounded-md border desktop:flex-row desktop:mx-[60px]">
          <div className="ml-8">
            <div className="flex items-center uppercase font-bold text-[14px]">
              <SMS className="w-6 h-6 desktop:-ml-9" />

              <span className="ml-3">{locale.bySMS}</span>
            </div>
            <p className="text-med-gray">
              {locale.placeholderPhone}
              <span className="ml-2 font-bold text-alert-positive">
                {locale.status}
              </span>
            </p>
            <p className="text-med-gray font-semibold text-[14px]">
              {locale.description}
            </p>
          </div>

          <div className="flex mt-[20px] items-center ml-[60px] min-w-[180px] uppercase justify-center desktop:mt-0">
            <ToggleComponent
              disabled
              labelLeft="off"
              labelRight="on"
              onChange={() => ({})}
              checked
            />
          </div>

          <Arrow className="w-3 h-5 absolute top-4 right-4 desktop:my-auto desktop:static desktop:w-8 desktop:h-12" />
        </div>

        <div className="flex justify-end w-full px-[60px] py-[30px] border-t">
          <ButtonComponent onClick={handleConfirmClick}>
            {locale.buttonConfirm}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default MultiFactorAuthConfirmProtect;
